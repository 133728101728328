<template>
  <section class="conOfsectionmap">
    <v-container class="conOfsection">
      <v-row>
        <v-col cols="12" md="4" v-show="active" class="m-2 p-2">
          <v-card  :elevation="2" class="mx-auto my-12 conOfAllFilter ">
            <v-btn
              class="sortBtn mr-2"
              :ripple="false"
              icon
              id="no-background-hover"
              @click="searchDone=false"
              ><v-icon class="sortIcon" color="#772767"
                >mdi-sort-variant</v-icon
              ></v-btn
            >
           <div v-show="!searchDone" class="conOfSearch">
             <div class="searchText">Search The Branches</div>
             <div class="moveOnTxt">
               MoveOn is available <br />all around KSA
             </div>
              
            
             <div class="selectStyle">

               <v-select

                 v-model="itemsSelected"
                 :items="items"
                 item-text="title"
                 item-value="value"
                 label="Branch"
                 bottom
                 outlined></v-select>
             </div>
            
             <div class="btnsStyle">
               <v-btn @click="initService()" class="SearchBtn defaultBtn mr-2" :disabled="itemsSelected==null" small dark>Search</v-btn>
               <v-btn
                 class="refreshBtn mr-2"
                 :ripple="false"
                 icon
                 id="no-background-hover"
               >
                 <v-icon color="#772767" @click="getRandomBranch"> mdi-rotate-3d-variant </v-icon></v-btn
               >
               

               <v-btn class="ResetBtn mr-2" small @click="itemsSelected = null">Reset</v-btn>
             </div>
           </div>
            <div v-show="searchDone" >
              <ul id="results" class="d-flex justify-center align-center flex-column"></ul>
            </div>
          </v-card>
        </v-col>
        <v-col :class="active ? 'col-md-8 col-12' : 'col-12'">
          <div class="sectionmap">
            <v-btn
              class="sideBtn"
              :ripple="false"
              icon
              id="no-background-hover"
              @click="active = !active"
              >&#8227;</v-btn
            >
            <Map />
          </div>
        </v-col>
      </v-row>

    </v-container>
  </section>
</template>

<script>

import Map from '@/components/Map'
import { eventBus } from '@/event-bus.js';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    Map
  },

  data () {
    return {
      searchDone:false,
      theLocation: '',
      theKeyword: '',
      cityState: '',
      active:true,
      curPlace: null,
      geocoder: null,
      myCityData: null,
     itemsSelected:null,
      clickedPharmacy:null,
      googleGeometryMultiPoly: [],
      numGeometries: 0,
      lat:0,
      lng:0,
      items:[
        { title: "AlNahdi", value: "Nahdi pharmacy, Saudi Arabia" },
        { title: "Aldwaa", value: " aldwa pharmacy saudi arabia" },
        { title: "Almohanna Pharmacies", value: "Almohanna Pharmacies saudi arabia" },
        { title: "AlMotaheda", value: "AlMotahida pharmacy saudi arabia" },
        { title: "AlNahas", value: "AlNahhas pharmacy saudi arabia" },
        { title: "ghaya", value: "ghaya pharmacy , saudi arabia" },
        { title: "Almogtama", value: "Almogtama pharmacy saudi arabia" },
        { title: "Zahrat Al Rawdah", value: "Zahrat Al Rawdah pharmacy saudi arabia" },
        { title: "Alsaggaf", value: "Alsaggaf pharmacy saudi arabia" },
        { title: "House of Health", value: "House of Health pharmacy saudi arabia" },
        { title: "Orange", value: "Orange pharmacy saudi arabia" },
        { title: "Limon", value: "Limon pharmacy saudi arabia" },
        { title: "Adel Pharmacies", value: "Adel Pharmacy saudi arabia" },
        { title: "Dawaa elsalamah", value: "Dawaa elsalamah pharmacy saudi arabia" },
        { title: "Exceer", value: "Exceer pharmacy saudi arabia" },
        { title: "Charisma ", value: "charisma pharmacy saudi arabia" },
        { title: "Boats Pharmacies", value: "Boats Pharmacies saudi arabia" },
        { title: "White Pharmacies ", value: "White Pharmacy saudi arabia" },
        { title: "Danoub", value: "AlDanoube pharmacy saudi arabia" },
        { title: "Manuel ", value: "Manuel pharmacy saudi arabia" },
        { title: "Anda ", value: "Anda pharmacy saudi arabia" },
      ]
    }
  },

  mounted: function() {
     

    // targets for emitted events
    eventBus.$on('googleInit', () => {
      this.initMapAutocomplete();
    });

    eventBus.$on('mapAddress', (payload) => {
      this.updateAddressFromMap(payload);
    });
     eventBus.$on('mapMarker', (payload) => {
      this.updateMarker(payload);
    });
  },

  methods: {
        getRandomBranch(){
          var randomBranch = this.items[Math.floor(Math.random()*this.items.length)];
          this.itemsSelected=randomBranch.value

        },
    // toss this over to the Map component
    launchPlacesSearch() {
      eventBus.$emit('launchKeywordSearch', this.theKeyword)
    },

    doClearLocation() {
      this.theLocation = '';
      this.curPlace = '';
    },

    getGeoJSON() {
      this.getCityData(this.cityState);
    },


    cityStateHelper(str) {
      let city = '';
      let state = '';

      if (!str) return '';

      const addressParts = str.split(',');
      city = addressParts[0];
      // just the first two characters...
      state = addressParts[1].trim().substring(0,2);
      return `${city},${state}`;
    },

    getCityState(address) {
      let cs = '';

      // this is not foolproof.. do a deep dive into the address components
      // and their type, if you are going to be more thorough.. I am just
      // working off of the formatted address
      // more info at https://developers.google.com/maps/documentation/geocoding/start?csw=1
      address.map(curAddress => {
        if (curAddress.types[0] === "locality") {
          cs = this.cityStateHelper(curAddress.formatted_address);
        }
      });
      return cs;
    },

    getCityData(theCity) {

      let apiPath = "https://nominatim.openstreetmap.org/search.php";


      let params = {
        q: `${theCity},USA`,
        polygon_geojson: 1,
        format: "json"
      };

      console.log(params);

      let polygonType = null;

      axios.get(apiPath, { params: params }  )
        .then(response => {
          let responseIndex = this.getCityIndex(response.data);
          let geoJSONDataChunk = response.data[responseIndex];

          polygonType = geoJSONDataChunk.geojson.type || null;

          // geojson data from http://nominatim.openstreetmap.org/ needs
          // to be wrapped, so that the google addGeoJson() call
          // can handle it properly
          const geoConf = {
            "type": "FeatureCollection",
            "features": [
              { "type": "Feature",
                "geometry": geoJSONDataChunk.geojson,
                "id": "city"
              }
            ]
          };

          this.myCityData = new window.google.maps.Data();
          this.myCityData.addGeoJson(geoConf, "city");
          this.myCityData.setStyle({
            fillColor: 'green',
            fillOpacity: 0.1,
            strokeWeight: 1
          });

          // we want to get geometry into our local objects, and create
          // google geometry objects with it.. hence the naming...
          let localCity = this.myCityData.getFeatureById('city');
          let allLocalMultiPolys = [];
          let localGeometry = null;

          // if a Polygon, our coordinates are at coordinates[0]
          // if a MultiPolygon, we loop through coordinates[0]...coordinates[n]
          if (polygonType === 'Polygon') {
            localGeometry = localCity.getGeometry();
            let numArrays = localGeometry.getLength();

            allLocalMultiPolys[0] = [];

            for (let i = 0; i < numArrays; i++) {
              allLocalMultiPolys[0].push(localGeometry.getAt(i).getArray());
            }

            this.googleGeometryMultiPoly[0] = new window.google.maps.Polygon({
              paths: allLocalMultiPolys[0]
            })

            this.numGeometries = 1;

          } else {
            localGeometry = localCity.getGeometry();
            let localGeometryArray = localCity.getGeometry().getArray();

            localGeometryArray.map((item, i) => {
              allLocalMultiPolys[i] = [];
              let curPolyNum = item.getLength();
              for (let j = 0; j < curPolyNum; j++) {
                allLocalMultiPolys[i].push(item.getAt(j).getArray());
              }
              this.googleGeometryMultiPoly[i] = [];
              this.googleGeometryMultiPoly[i] = new window.google.maps.Polygon({
                paths: allLocalMultiPolys[i]
              });
            });

            this.numGeometries = localGeometry.getLength();
          }

          // send boundary geometry and polygon data to our Map component
          eventBus.$emit('sendCityData', {
            coordinates: this.myCityData,
            multiPoly: this.googleGeometryMultiPoly,
            numGeometries: this.numGeometries
          });
        });
    },


    /*
    ** getCityIndex -  figure out which chunk of data contains city boundaries,
    ** or punt to administrative (we cant assume that data[0]
    */
    getCityIndex(data) {
      let retIndex = null;
      let adminIndex = null;

      console.log(data);

      data.map((curData, index) => {
        // console.log(curData.type);
        switch(curData.type) {
          case "city":
            retIndex = index;
            break;
          case "administrative":
            adminIndex = index;
            break;
          default:
            break;
        }
      });

      // ... we punt to administrative...
      if (retIndex === null && adminIndex !== null) {
        retIndex = adminIndex;
      }

      return retIndex;
    },
     initService() {
      var that = this
       this.searchDone= true

       const pharmacy = this.itemsSelected
       //   function getPlace(){
       //     const request = {
       //       query: pharmacy,
       //       fields: ["name", "geometry"],
       //     };
       //
       //     service = new window.google.maps.places.PlacesService(document.getElementById("map"));
       //
       //     service.findPlaceFromQuery(
       //       request,
       //       (
       //         results,
       //         status
       //       ) => {
       //         if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
       //           for (let i = 0; i < results.length; i++) {
       //             createMarker(results[i]);
       //
       //           }
       //           console.log(results[0]);
       //
       //           //that.map.setCenter(results[0].geometry!.location!);
       //         }
       //       }
       //     );
       //   }
       // function createMarker(place) {
       //
       //  var infowindow = new window.google.maps.InfoWindow();
       //   if (!place.geometry || !place.geometry.location) return;
       //
       //   const marker = new window.google.maps.Marker({
       //     position: place.geometry.location,
       //   });
       //   marker.setMap(that.map);
       //   window.google.maps.event.addListener(marker, "click", () => {
       //     infowindow.setContent(place.name || "");
       //     infowindow.open(document.getElementById("map"));
       //   });
       // }
       function getCoordinates(address){
         fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+address+'&key=AIzaSyDy3-Dq5Ast3DYAzaTEkeuSZxP_4rKPiAA')
           .then(response => response.json())
           .then(data => {
             that.lat = data.results[0].geometry.location.lat;
             that.lng= data.results[0].geometry.location.lng;
             initMap()
           })
          
       }
         function initMap() {
      that.map = new window.google.maps.Map(document.getElementById("map"), {
        center: {
          lat: that.lat,
          lng: that.lng
        },
        zoom: 16,
        gestureHandling: "greedy"
      });
      var myLatlng = {lat:that.lat, lng:that.lng }
      console.log(myLatlng);
       let marker = new window.google.maps.Marker({
      position: myLatlng,
    
    });
    
    marker.setMap(that.map);
      // now we can init other things that depend on Google being loaded
      // tell the event bus
      eventBus.$emit('googleInit', {});
       }

      
  var displaySuggestions = function(predictions, status) {
    document.getElementById('results').innerHTML=''
    if (status != window.google.maps.places.PlacesServiceStatus.OK) {
      return;
    }
    predictions.forEach(function(prediction) {
      var li = document.createElement('li');
      li.classList.add("listStyle")
      li.id="current"
     li.addEventListener(
        "click",getclickedPharmacy
      )
     
      function getclickedPharmacy(e) {

        this.clickedPharmacy=e.target.innerHTML
       getCoordinates(e.target.innerHTML)
       
      }

      li.appendChild(document.createTextNode(prediction.description.replace(', USA', '')));
      document.getElementById('results').appendChild(li);
    });
  };
  var service = new window.google.maps.places.AutocompleteService();
  service.getQueryPredictions({
    input: (' ' + pharmacy + ' ').replace(/ usa /ig,' ').replace(/ US /ig,' ').replace(/ united states /ig,' ') + ' usa'
  }, displaySuggestions);
},
  updateMarker(latLng) {
      if (!this.marker) {
        this.marker = new window.google.maps.Marker({
          map: this.map
        });
      }

      this.marker.setPosition(latLng);
    },
    updateAddressFromMap(payload) {
      // TODO: error checking...
      const firstAddress = payload[0].formatted_address;

      this.theLocation = firstAddress;
      // if we have something here, it makes the 'get city' button visible
      this.curPlace = this.theLocation;

      // parse the address to get city and state
      this.cityState = this.getCityState(payload);
    },


    initMapAutocomplete() {
      this.geocoder = new window.google.maps.Geocoder();


      this.curPlace = this.clickedPharmacy
      this.theLocation = this.clickedPharmacy

      if (typeof this.curPlace !== "undefined") {
        // parse the address to get city and state
        this.cityState = this.cityStateHelper(this.curPlace);

        // we need to update the map
        eventBus.$emit('newTextAddress', this.curPlace);
      }
    }}

}
</script>


<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(119 39 103) !important;
}
.sortIcon {
  color: #772767;
 
  padding: 12px 15px;
  font-size: 20px;
}
.sortBtn {
  padding: 35px;
}
.ResetBtn {
  width: 130px;
  background-color: transparent !important;
  color: #000000;
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
  padding: 20px 20px !important;
  border-radius: 15px;
  justify-content: center;
}
.SearchBtn {
  width: 130px;
  background-color: #772767 !important;
  color: #ffffff;
  border: 1px solid #772767;
  font-family: "Montserrat-SemiBold";
  font-size: 15px;
  padding: 20px 20px !important;
  border-radius: 15px;
  justify-content: center;
}
.btnsStyle {
  padding: 20px;
  text-align: center;
  justify-content: center;
}
.searchText {
  padding: 5px 18px;
  font-size: 20px;
  font-weight: bold;
}
.moveOnTxt {
  padding: 5px 18px;
  font-size: 15px;
  color: #515251;
}
.conOfAllFilter {
  height: 75vh;
  overflow: auto;
}

.sectionmap {
  height: 100vh;
  padding-top: 55px;
}

.selectStyle {
  color: #772767;
  padding: 18px;
}
.sideBtn {
  position: absolute;
  top: 140px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #772767;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  z-index: 9999;
}
.refreshBtn {
  padding: 10px;
}
::v-deep{
  .listStyle{
    background: #f6f1f5;
    border-radius: 10px;
    padding: 25px;
    max-width: 70%;
    list-style: none;
    margin: 5px;
    cursor: pointer;

  }
}

@media screen and (max-width: 768px) {
  .conOfAllPharmacy {
    height: 300px;
    overflow: auto;
  }
  .sectionmap{
    padding-top: 2px;
    height: 50vh;
    position: relative;
  }
  .sideBtn{
    top: 18px;
  }
  .conOfAllFilter{
    height: 45vh;
    margin-bottom: 0 !important;
  }
}

::v-deep {
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #8f2f7c;
    border-radius: 15px;
  }

  .theme--light.v-label {
    color: #8f2f7c;
  }
  
}
@media screen and  (min-width: 1880px) {
  .container{
    max-width: 1600px;
  }
  
}
</style>
